/*FOOTER*/
.footer {
  position:relative; display:block; 
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("../../imgs/apolonia-hotel-footer-bg.jpg");
}
.footer,
.footer a {color:#ffffff;}
.footer .container {
  z-index:2; text-align:center;
  padding-top:35px;
  padding-bottom:35px;
}
.footer .container > div {padding-bottom:20px;}
.footer .container > div:last-child {padding-bottom: 0;}
.footer .col-socials a {display:inline-block;margin-left:20px; margin-right:20px;}
.footer .container .col-info {line-height: 1.8em; font-size:0.8em;}
.footer .container .col-info span {padding-left:15px; padding-right:15px;}
.footer .container .col-copy { font-size:0.8em;}
.footer-bg {
  z-index:1; content:""; position: absolute; 
  top:0; left:0; right:0; bottom:0;
  width:100%; height:100%;
  background-image: url("../../imgs/apolonia-hotel-footer-bg.jpg");
  background-position: center center;
  background-repeat:no-repeat;
  background-size: cover;
  opacity:.3;
  filter: blur(14px);
  -webkit-filter: blur(14px);
  overflow:hidden;
}

/*back to top*/
.back-to-top {
  z-index: 2000011;
  position: fixed; cursor:pointer; outline:0;  
  bottom: 20px; right: -50px; text-align:center;
  opacity: 0; transition: opacity 0.5s ease-in-out 0s, right; 
  border:1px solid #C8A264; border-radius: 100%;
}
.back-to-top {width:50px; height:50px; }
.back-to-top span {
  display: flex; 
  align-items:center; justify-content: center; 
  width:100%; height:100%;
  color:#C8A264; font-weight:600; text-align:center;
  font-size:2.5em; line-height:1px;
}
.back-to-top span img {width:50%; height:auto; display: block; }
.ScrollUpButton__Toggled {
  right: 20px; opacity: 1;
  transition: opacity 0.5s ease-in-out 0s, right; 
}
/*end back to top*/

.footer {position:relative; display:block; color:#ffffff}
.footer-content-wrap {
  position:relative; z-index:2; 
  background-color:rgba(32, 30, 24, .8);  
  padding-top:40px; padding-bottom:100px;
}
.footer-overflow {position:relative; overflow: hidden}

@media (max-width:1600px) {
  .back-to-top  {width:40px; height:40px; }
}
@media (max-width:960px) {
  .ScrollUpButton__Toggled { right: 10px;}
  .ScrollUpButton__Toggled,
  .back-to-top {bottom:40px;}
}
@media (max-width:900px) {
  .footer .col-info span {display:block; padding-bottom:10px; text-align:center}
}
/*end FOOTER*/