/*HEADER*/
.header {
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  background: rgba(133, 106, 61, 0.8);
  background: linear-gradient(to bottom, rgba(133, 106, 61, 0.8) 0%, rgba(133, 106, 61, 0) 100%);
  height: 20vh;
  /* other styles remain unchanged */
}

.header-top,
.header-content {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: center;

}
.header-top {
  justify-content: space-between;
  padding-top: 15px;
  font-weight: 600;
  line-height: 1em;
  max-width: 75%;
  margin: 0 auto;
}
.header-top a {
  color: #ffffff;
}
.header-top .col-left {
  font-size: 0.625em;
}
.header-top .col-left > span {
  padding-right: 10px;
}
.header-top .col-left span:last-child {
  display: block;
}
.header-top .col-left .icon {
  padding-right: 2px;
}
.header-top .col-right > div {
  display: inline-block;
}
.header-top .col-right .socials {
  padding-right: 40px;
  font-size: 0.8em;
}
.header-top .col-right {
  font-size: 0.625em;
}
.header-top .col-right span:last-child {
  display: block;
  text-align: right;
}
.header-top .col-right .socials a {
  padding-left: 10px;
  padding-right: 10px;
}

.header-content {
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}
.header-content .col-logo .logo {
  display: block;
} /*max-width: 171px; height:auto;*/

/*mobile*/
.burger-icon {
  display: none;
  cursor: pointer;
}

#burger-icon {
  width: 28px;
  height: 19px;
  position: relative;
  margin: auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#burger-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.nav-main-toggle {
  height: 100%;
  z-index: 1060; 
}
.nav-main-toggle.isOpen > .col-navmain {
  position: relative;
  z-index: 1060; 
  height: 100%;
}
.navbar-collapse {
  position: relative;
  z-index: 1055; /* Just below the reservation banner */
}
#burger-icon span:nth-child(1) {
  top: 0px;
}
#burger-icon span:nth-child(2) {
  top: 10px;
}
#burger-icon span:nth-child(3) {
  top: 20px;
}
.nav-main-toggle.isOpen #burger-icon span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.nav-main-toggle.isOpen #burger-icon span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.nav-main-toggle.isOpen #burger-icon span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.m-header-top,
.header-content .col-logo-m {
  display: none;
}
/*end mobile*/

/*MEDIA*/
@media (max-width: 1024px) {
  .header-top .col-right .socials {
    padding-right: 20px;
  }
}
@media (max-width: 900px) {
  .burger-icon {
    display: block;
  }
  .nav-main-toggle,
  .nav-main-toggle.isOpen {
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
  }

  .header-top {
    display: none;
  }
  .m-header-top,
  .m-header-top .header-top,
  .header-content .col-logo-m {
    display: block !important;
  }

  .nav-main-toggle {
    z-index: 50;
    position: fixed;
    top: 0;
    right: 100%;
    width: 80%;
    margin: 0;
    padding: 0;
    display: block;
    height:100%;
    max-height: 100vh;
  }
  .nav-main-toggle.isOpen {
    right: 20%;
  }
  .nav-main-toggle .burger-icon {
    position: absolute;
    right: -55px;
    top: 25px;
  }
  .nav-main-toggle .col-navmain {
    background-color: #ffffff;
    max-height: 100vh;
    overflow: auto;
  }

  .header-content {
    display: block;
    text-align: center;
  }
  .header-content .col-logo-m {
    padding: 100px 0 50px 0;
  }
  .header-content .col-logo {
    text-align: center;
  }
  .header-content .col-logo .logo {
    margin-left: auto;
    margin-right: auto;
  }

  .header-content .col-navmain,
  .header-content .col-navmain .nav-main,
  .header-content .col-navmain .nav-main a,
  .header-top .col-right .socials,
  .header-top .col-right .socials a,
  .header-top .nav-langs li,
  .header-top .nav-langs .item a {
    color: #c8a264;
  }

  .col-navmain .nav-main-wrap {
    padding: 0;
  }
  .header-content .col-navmain .nav-main,
  .header-content .col-navmain .nav-main a {
    text-decoration: none;
  }
  .header-content .col-navmain .nav-main .item {
    display: block;
    padding: 10px;
    text-align: center;
  }
  .header-top .icon {
    padding-right: 0;
  }
  .header-top .col-left {
    padding-bottom: 15px;
  }
  .header-top .col-left .icon {
    display: block;
    margin: auto;
  }
  .header-top .col-right {
    padding-bottom: 20px;
  }
}
/*end MEDIA*/

/*

@media(max-width:900px) {
  .header .col-nav-main {padding-top:2em; padding-bottom:2em;}
  .header .col-logo {text-align:left; padding-top:1em; padding-bottom:1em;}
  .header .logo img {display: block; margin: 0; width:56px; }

  .icon-burger {display:block}

  .header .row {background-color:rgba(32, 30, 24, .4)}


}


/*end HEADER*/
