.reservation-banner-container {
  height: 70px;
  position: absolute;
  width: 100%;
  z-index: 4;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto; /* Allow clicks */
}

.reservation-banner-container .navbar {
  height: auto;
  min-height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(200, 162, 100, 0.9) 0%, rgba(150, 120, 80, 0.9) 100%);

}

.reservation-banner-container form[data-clock-pms-wbe-form] {
  background: inherit; /* Inherit the gradient from the navbar */
  margin: 0 auto;
}

.reservation-banner-container p {
  font-family: regular;
  font-size: 16px;
}

.reservation-banner-container p .hover {
  text-decoration: underline;
}

.reservation-banner-container .reserve-button {
  padding: 10px 20px;
  background-color: white;
  color: black;
  font-family: 'Roboto';
  font-weight: 900;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}


.reservation-banner-container .form-control input[type="date"]{
  font-size: 0.8em;
}

label[for="form-bonus-code"]{
  font-size: 0.8em;
}


.reservation-banner-container .form-floating>label{
  width: 100%;
}

.reservation-banner-container .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  transform: scale(.65) translateY(-.5rem) translateX(.15rem);
}

.reservation-banner-container .btn{
  font-weight: bold;
  width: auto;
}


@media (max-width: 767.98px) { 
  .reservation-banner-container .row {
    padding: 1em 1em;
    margin: 0.6em 0;    
  }

  .reservation-banner-container .form-control input{
    text-align: center;
  }
  
  .reservation-banner-container .btn{
    width: 100%;
  }

  .reservation-banner-container .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    transform: scale(.65) translateY(-0.5rem) translateX(10em);
  }

}
