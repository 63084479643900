/* Gallery Styles */
.header {
  position: relative;
  height: auto;
  background: rgba(133, 106, 61, 1);
  background: -moz-linear-gradient(
    top,
    rgba(133, 106, 61, 1) 0%,
    rgba(133, 106, 61, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(133, 106, 61, 1)),
    color-stop(100%, rgba(133, 106, 61, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(133, 106, 61, 1) 0%,
    rgba(133, 106, 61, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(133, 106, 61, 1) 0%,
    rgba(133, 106, 61, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgb(190, 189, 186) 0%,
    rgba(133, 106, 61, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(133, 106, 61, 1) 0%,
    rgba(133, 106, 61, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#856a3d', endColorstr='#856a3d', GradientType=0 );
}

.gallery {
  padding-bottom: 50px;
}

.gallery-photos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.gallery-photos > div {
  cursor: pointer;
  width: 30%;
  display: flex;
  justify-content: center;
}

.gallery-photos img {
  width: 100%;
  height: 200px; /* Fixed height */
  object-fit: cover; /* Ensure aspect ratio is maintained */
  transition: transform 0.3s ease-in-out;
}

.gallery-photos img:hover {
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .gallery-photos > div {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .gallery-photos > div {
    width: 100%;
  }
}
